<template>
  <NavigationMenuItem
    class="h-10 flex items-center px-2 rounded w-full transition text-white hover:bg-[#DEE8FC] hover:text-[#5A88E1]"
    :class="
      twMerge(
        isActiveMenuItem(item.to) ? 'bg-[#DEE8FC]' : '',
        item.isFixed && 'bg-[#234890]'
      )
    "
  >
    <NuxtLink :href="item.to" class="flex items-center menu-item w-full">
      <Icon
        v-if="typeof item.icon === 'string'"
        :name="item.icon"
        class="flex-shrink-0 hover:text-[#5A88E1]"
        :class="[
          isActiveMenuItem(item.to) && !item.isFixed ? 'text-[#5A88E1]' : '',
          !item.children ? ' h-6 w-6' : ' h-6 w-6',
        ]"
      />
      <component
        v-else
        :is="item.icon"
        class="flex-shrink-0 hover:text-[#5A88E1]"
        :class="[
          isActiveMenuItem(item.to) && !item.isFixed ? 'text-[#5A88E1]' : '',
          !item.children ? ' h-6 w-6' : ' h-6 w-6',
        ]"
      />
      <div
        :title="item.label"
        class="ml-2 hidden menu-label whitespace-nowrap text-ellipsis overflow-hidden font-bold"
        :class="[
          isActiveMenuItem(item.to) && !item.isFixed ? 'text-[#5A88E1]' : '',
        ]"
      >
        {{ item?.children ? t(`dashboard.menu.${item.label}`) : item.label }}
      </div>
    </NuxtLink>
  </NavigationMenuItem>
  <template v-if="item.children && item.children.length > 0">
    <SassNabBarMenu
      v-for="child in item.children"
      :key="child.label"
      :item="child"
    />
  </template>
</template>

<script setup>
import { NavigationMenuItem } from "radix-vue";
import { twMerge } from "tailwind-merge";
import SassNabBarMenu from "./SassNavBarMenu.vue";

defineProps({
  item: {
    type: Object,
    required: true,
  },
});
const { t } = useTranslations();

const route = useRoute();

const isActiveMenuItem = (href) => {
  return href && route.path.includes(href);
};
</script>

<style scoped>
.sidebar:hover .menu-item {
  justify-content: flex-start;
  white-space: nowrap;
}

.sidebar:hover .menu-label {
  animation: fadeIn 0.3s forwards;
  display: block;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
