import {
  Banknote,
  BellRing,
  BookUser,
  // BriefcaseBusiness,
  Building2,
  BuildingIcon,
  ChartLine,
  CircleUser,
  Cog,
  ContactRound,
  HardDrive,
  ScrollText,
  Server,
  ShieldCheck,
  SquareUserRound,
  UserRoundCog,
  UserRoundPen,
  UsersRound,
} from "lucide-vue-next";
import type { IconName } from "~/modules/ui/components/Icon.vue";

export type MenuItem = {
  label: string;
  to: string;
  icon: IconName | Component;
  enabled?: boolean;
  isFixed?: boolean;
  children?: MenuItem[];
};

export const getMenuItems = ({
  userRole,
  t,
  pathname,
}: {
  userRole: {
    isSubscribed: boolean;
    isAdmin: boolean;
    isClient: boolean;
    isPromoter?: boolean;
    isSeller?: boolean;
  };
  t: any;
  pathname: string;
}): MenuItem[] => {
  const menuConfig: { [key: string]: MenuItem[] } = {
    PROSPERE: [
      {
        label: t("dashboard.menu.dashboard"),
        icon: "heroHome",
        to: "/prospere/dashboard",
      },
      {
        label: t("dashboard.menu.classicalResearch"),
        icon: "heroSearch",
        to: "/prospere/classical-search",
      },
      {
        label: t("dashboard.menu.AIResearch"),
        icon: "aiSearch",
        to: "/prospere/ai-search",
      },
      {
        label: t("dashboard.menu.mailing"),
        icon: "heroMail",
        to: "",
      },
      {
        label: t("dashboard.menu.savedSearches"),
        icon: "heroClipboard",
        to: "/prospere/save-search",
      },
      {
        label: t("dashboard.menu.favorites"),
        icon: "heroStar",
        to: "/prospere/favorites",
      },
      {
        label: t("dashboard.menu.presentation"),
        icon: "presentations",
        to: "/prospere/presentations/list",
      },
      {
        label: t("dashboard.menu.settings"),
        icon: "heroCog",
        to: "/prospere/preferences",
      },
      {
        label: t("dashboard.menu.profile"),
        icon: "heroUser",
        to: "/prospere/settings",
      },
    ],
    PRETYS: [
      {
        label: t("dashboard.menu.dashboard"),
        icon: ChartLine,
        to: "/pretys/dashboard",
      },
      {
        label: "Mes clients",
        icon: UserRoundPen,
        to: "/pretys/transaction",
      },
      // {
      //   label: "Dossiers courtage",
      //   icon: BriefcaseBusiness,
      //   to: "/pretys/transaction",
      // },
      {
        label: "Notifications",
        icon: BellRing,
        to: "/pretys/notifications",
      },
      {
        label: "Paramètres",
        icon: Cog,
        to: "/pretys/preferences",
      },
    ],
    PROSPEREPLUS: [
      {
        label: t("dashboard.menu.dashboard"),
        icon: "heroHome",
        to: "/prospere-plus/dashboard",
      },
      {
        label: "Gestion programmes",
        icon: "building",
        to: "/prospere-plus/program",
      },
    ],
    CONTACT: [
      {
        label: t("dashboard.menu.dashboard"),
        icon: "heroHome",
        to: "/contact/dashboard",
      },
      {
        label: "Mes individus",
        icon: UserRoundPen,
        to: "/contact/list",
      },
      {
        label: "Mes projets",
        icon: ScrollText,
        to: "/contact/project/list",
      },
      {
        label: "Base individus",
        icon: Server,
        to: "/contact/base/list",
      },
      {
        label: "Base Project",
        icon: HardDrive,
        to: "/contact/project/base/list",
      },
      {
        label: "Dénonciations",
        icon: BookUser,
        to: "/contact/denunciation/list",
      },
      {
        label: "Gestion des dénonciations",
        icon: UserRoundCog,
        to: "/contact/denunciation/overview/list",
      },
      {
        label: "Recherche rapide",
        icon: "building",
        to: "/contact/project/quick_search",
      },
      {
        to: "/contact/preferences",
        label: "Mes paramètres",
        icon: Cog,
      },
    ],
    EQUIPE: [
      {
        label: "Mes équipes",
        icon: ContactRound,
        to: "/team",
      },
    ],
    CLIENT: [
      {
        label: "Dashboard",
        icon: "heroHome",
        to: "/client/dashboard",
      },
      {
        label: "Financements",
        icon: "handCoins",
        to: "/client/transaction",
      },
      {
        label: "Notifications",
        icon: "mail",
        to: "/client/notifications",
      },
      {
        label: "Paramètres",
        icon: CircleUser,
        to: "/client/preferences",
      },
    ],
  };
  const ADMIN = [
    {
      label: "Gestion des entreprises",
      icon: BuildingIcon,
      to: "/admin/company",
    },
    {
      label: "Gestion des équipes",
      icon: BuildingIcon,
      to: "/admin/team",
    },
    {
      label: "Gestion des utilisateurs",
      icon: UsersRound,
      to: "/admin/users",
    },
    {
      label: "Gestion du dashboard",
      icon: UsersRound,
      to: "/admin/dashboard",
    },
  ];
  if (userRole.isClient) {
    return menuConfig.CLIENT;
  } else {
    delete menuConfig.CLIENT;
  }
  if (userRole.isAdmin) {
    menuConfig.ADMIN = ADMIN;
  }
  if (!userRole?.isSubscribed) {
    menuConfig.PROSPERE = [
      {
        label: t("dashboard.menu.profile"),
        icon: "heroUser",
        to: "/prospere/settings",
      },
    ];
  }
  if (!userRole.isPromoter) {
    delete menuConfig.PROSPEREPLUS;
  }
  if (!userRole.isSeller) {
    return menuConfig.PROSPERE;
  }

  const labelIconMap: { [key: string]: any } = {
    PROSPERE: Building2,
    PRETYS: Banknote,
    PROSPEREPLUS: "program",
    CONTACT: SquareUserRound,
    EQUIPE: UsersRound,
    ADMIN: ShieldCheck,
  };
  const labelMainPathMap: { [key: string]: string } = {
    PROSPERE: "/prospere",
    PRETYS: "/pretys",
    PROSPEREPLUS: "/prospere-plus",
    CONTACT: "/contact/list",
    EQUIPE: "/team",
    ADMIN: "/admin/company",
  };

  const items: MenuItem[] = Object.keys(menuConfig).map((label) => ({
    label,
    icon: labelIconMap[label] || "admin",
    to: labelMainPathMap[label],
    isFixed: true,
    children: menuConfig[label].filter((_item) => {
      const currentType = pathname.replace(/\/$/, "").split("/")[1];
      return _item.to.split("/")[1] === currentType;
    }),
  }));

  return items;
};
