<script setup lang="ts">
import { NavigationMenuList, NavigationMenuRoot } from "radix-vue";
import { getMenuItems, type MenuItem } from "~/routes/menus";
import SassNavBarMenu from "./SassNavBarMenu.vue";
import { useCompany } from "~/modules/pretys/company/composables/useCompany";

const props = defineProps<{
  isMobile: boolean;
}>();
const route = useRoute();
const { user } = useUser();
const { currentCompany } = useCompany();
const { t } = useTranslations();
const isAdmin = computed(() => user.value?.pretysRole === "ADMIN");
const isClient = computed(() => user.value?.pretysRole === "CLIENT");
const isPromoter = computed(
  () => currentCompany.value?.companyType.startsWith("PROMOTEUR") || false
);
const isSeller = computed(() => currentCompany.value !== null);
const isSubscribed = computed(() => user.value?.isSubscribed);
// calculate menu items based on user role
const menuItems = computed(() => {
  return (
    getMenuItems({
      userRole: {
        isSubscribed: isSubscribed.value as boolean,
        isAdmin: isAdmin.value,
        isClient: isClient.value,
        isPromoter: isPromoter.value,
        isSeller: isSeller.value,
      },
      t,
      pathname: route.path,
    }) || []
  );
}) as Ref<MenuItem[]>;
</script>

<template>
  <div
    class="w-16 shadow flex items-center fixed left-2 top-24 bottom-16 rounded-lg text-base sidebar"
    v-if="!props.isMobile"
  >
    <div class="w-full h-full">
      <NavigationMenuRoot orientation="vertical">
        <NavigationMenuList
          class="flex flex-col gap-2 rounded-full items-center py-4 mx-auto"
        >
          <SassNavBarMenu
            v-for="item in menuItems"
            :key="item.label"
            :item="item"
          />
        </NavigationMenuList>
      </NavigationMenuRoot>
    </div>
  </div>
</template>

<style scoped>
.sidebar:hover {
  width: 14em;
}

.sidebar {
  background-color: #5a88e1;
  z-index: 1000;
  transition: width 0.3s;
  padding: 0 12px;
  box-sizing: border-box;
  overflow: hidden;
}
</style>
