<template>
  <DropdownMenuRoot v-if="user">
    <p class="text-lg font-extrabold text-gray-800" v-if="!props.isMobile">
      {{ user.name }}
    </p>
    <DropdownMenuTrigger as-child>
      <button
        class="focus-visible:ring-primary rounded-full outline-none focus-visible:ring-2"
      >
        <UserAvatar :name="user.name ?? ''" :avatar-url="user.avatarUrl" />
      </button>
    </DropdownMenuTrigger>

    <DropdownMenuContent align="end">
      <DropdownMenuLabel>
        {{ user.name }}
        <span class="block text-xs font-normal opacity-70">
          {{ user.email }}
        </span>
      </DropdownMenuLabel>

      <DropdownMenuSeparator />

      <!-- Color mode selection -->
      <DropdownMenuSub>
        <DropdownMenuSubTrigger class="cursor-pointer">
          <ColorModeToggleIcon />
          <div class="ml-2">
            {{ t("dashboard.userMenu.colorMode") }}
          </div>
        </DropdownMenuSubTrigger>
        <DropdownMenuPortal>
          <DropdownMenuSubContent avoid-collisions>
            <ColorModeToggleRadioGroup />
          </DropdownMenuSubContent>
        </DropdownMenuPortal>
      </DropdownMenuSub>

      <DropdownMenuSeparator />

      <!-- Locale selection -->
      <DropdownMenuSub>
        <DropdownMenuSubTrigger class="cursor-pointer">
          <Icon name="language" class="h-4 w-4" />
          <div class="ml-2">
            {{ t("dashboard.userMenu.language") }}
          </div>
        </DropdownMenuSubTrigger>
        <DropdownMenuPortal>
          <DropdownMenuSubContent avoid-collisions>
            <LocaleSwitchRadioGroup />
          </DropdownMenuSubContent>
        </DropdownMenuPortal>
      </DropdownMenuSub>

      <DropdownMenuSeparator />

      <DropdownMenuItem as-child>
        <NuxtLinkLocale
          to="/prospere/settings/account/general"
          class="cursor-pointer"
        >
          <Icon name="settings" class="mr-2 h-4 w-4" />
          {{ t("dashboard.userMenu.accountSettings") }}
        </NuxtLinkLocale>
      </DropdownMenuItem>

      <DropdownMenuItem
        v-if="user.impersonatedBy"
        @click="unimpersonate()"
        class="cursor-pointer"
      >
        <Icon name="unimpersonate" class="mr-2 h-4 w-4" />
        {{ t("dashboard.userMenu.unimpersonate") }}
      </DropdownMenuItem>

      <DropdownMenuItem @click="logout" class="cursor-pointer">
        <Icon name="logout" class="mr-2 h-4 w-4" />
        {{ t("dashboard.userMenu.logout") }}
      </DropdownMenuItem>
    </DropdownMenuContent>
  </DropdownMenuRoot>
</template>

<script setup lang="ts">
const { user, logout } = useUser();
const { t } = useTranslations();
const { toast } = useToast();
const { apiCaller } = useApiCaller();
const props = defineProps<{
  isMobile: boolean;
}>();

const unimpersonateMutation = apiCaller.admin.unimpersonate.useMutation();

const unimpersonate = async () => {
  toast({
    variant: "loading",
    title: t("admin.users.impersonation.unimpersonating"),
  });
  await unimpersonateMutation.mutate();
  window.location.reload();
};
</script>
