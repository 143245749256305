<template>
  <div>
    <SaasHeader :is-mobile="isMobile" />

    <main class="flex flex-row max-w-7xl 2xl:mx-auto sm:mx-8 py-4 mt-16">
      <ClientOnly>
        <SaasNavBarV2 :is-mobile="isMobile" />
      </ClientOnly>
      <section class="grow lg:ml-8 p-4">
        <slot />
      </section>
      <EmailPopup />
    </main>
  </div>
</template>

<script setup lang="ts">
import { useMediaQuery } from "@vueuse/core";
import { useSettingsStore } from "~/modules/saas/settings/composables/useSettingsStore";

const isMobile = useMediaQuery("(max-width: 1024px)");
const { apiCaller } = useApiCaller();

// retrieve user settings
const settingsStore = useSettingsStore();

if (!settingsStore.value.userSettings) {
  try {
    const userSettings = await apiCaller.settings.retrieveUserSettings.query();
    settingsStore.value.userSettings = {
      location_list: userSettings.location_list,
      promoteur_list: userSettings.promoteur_list,
    };
  } catch (e) {
    console.error(e);
  }
}
</script>
