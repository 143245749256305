<template>
  <div
    class="bg-white fixed left-0 top-0 z-20 w-full backdrop-blur-lg transition-[height] duration-200 shadow-sm"
  >
    <div class="mx-8">
      <div class="flex flex-wrap items-center justify-between gap-4">
        <div class="flex items-center">
          <NuxtLinkLocale to="/" class="block">
            <Logo />
          </NuxtLinkLocale>

          <!-- <span class="hidden opacity-30 lg:block">
            <Icon name="chevronRight" class="h-4 w-4" />
          </span> -->

          <!-- <SaasTeamSelect /> -->
        </div>

        <div class="ml-auto mr-0 flex items-center justify-end gap-4">
          <ClientOnly>
            <UserMenu :is-mobile="props.isMobile" />
          </ClientOnly>

          <SheetRoot v-model:open="mobileMenuOpen">
            <SheetTrigger as-child>
              <Button class="lg:hidden" size="icon" variant="outline">
                <Icon name="menu" />
              </Button>
            </SheetTrigger>
            <SheetContent class="w-[250px]" side="right">
              <VisuallyHidden>
                <DialogTitle>Menu</DialogTitle>
                <DialogDescription>Navigation Menu</DialogDescription>
              </VisuallyHidden>
              <div class="flex flex-col items-start justify-center">
                <NuxtLinkLocale
                  v-for="menuItem of menuItems"
                  :key="menuItem.to"
                  :to="menuItem.to"
                  class="block px-3 py-2 text-lg"
                >
                  {{ menuItem.label }}
                  <template
                    v-if="menuItem.children && menuItem.children.length > 0"
                  >
                    <NuxtLink
                      v-for="child in menuItem.children"
                      :key="child.label"
                      :to="child.to"
                      class="block px-3 py-2 text-lg"
                    >
                      {{ child.label }}
                    </NuxtLink>
                  </template>
                </NuxtLinkLocale>
              </div>
            </SheetContent>
          </SheetRoot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { VisuallyHidden } from "radix-vue";
import { useCompany } from "~/modules/pretys/company/composables/useCompany";
import { getMenuItems, type MenuItem } from "~/routes/menus";

const { t } = useTranslations();
const route = useRoute();
const { user } = useUser();
const { currentCompany } = useCompany();
const isAdmin = computed(() => user.value?.pretysRole === "ADMIN");
const isClient = computed(() => user.value?.pretysRole === "CLIENT");
const isPromoter = computed(
  () => currentCompany.value?.companyType.startsWith("PROMOTEUR") || false
);
const isSeller = computed(() => currentCompany.value !== null);
const isSubscribed = computed(() => user.value?.isSubscribed);
// calculate menu items based on user role
const props = defineProps<{
  isMobile: boolean;
}>();

const menuItems = computed(() => {
  return (
    getMenuItems({
      userRole: {
        isSubscribed: isSubscribed.value as boolean,
        isAdmin: isAdmin.value,
        isClient: isClient.value,
        isPromoter: isPromoter.value,
        isSeller: isSeller.value,
      },
      t,
      pathname: route.path,
    }) || []
  );
}) as Ref<MenuItem[]>;

const mobileMenuOpen = ref(false);

watch(
  () => route.fullPath,
  () => {
    mobileMenuOpen.value = false;
  }
);
</script>
