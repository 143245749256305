<template>
  <div v-if="emailState.open">
    <div
      v-if="emailState.maximized"
      class="bg-white w-full h-full wrapper-email-popup"
    >
      <div class="email-popup rounded-[15px] p-0 flex flex-col pb-4">
        <div
          class="w-full min-h-[56px] bg-[#F2F4FA] flex flex-row justify-between items-center gap-4 px-4 rounded-t-[13px]"
        >
          <span class="flex-1 text-[#2C3449] font-medium text-[20px]">
            Nouvelle message
          </span>
          <Icon name="expanded" class="cursor-pointer" @click="minimizePopup" />
          <Icon name="close" class="cursor-pointer" @click="emailPopupClose" />
        </div>
        <div class="px-4 mt-4 h-full">
          <form @submit.prevent="sendEmail" class="flex flex-col gap-4">
            <div
              class="w-full flex flex-row gap-4 justify-between items-center border-b-2 border-[#E6E7E8]"
            >
              <input
                type="text"
                placeholder="Recipient email"
                v-model="to"
                @input="updateField('to', to)"
                id="to"
                class="flex-1 border-none outline-none shadow-none no-shadow-box"
              />
              <span
                v-if="!visibleCC"
                class="h-full text-[#688197] cursor-pointer"
                @click="handleCC"
              >
                CC
              </span>
              <span
                v-if="!visibleBcc"
                class="h-full text-[#688197] cursor-pointer"
                @click="handleBcc"
              >
                Cci
              </span>
            </div>
            <input
              v-if="visibleCC"
              id="cc"
              v-model="cc"
              @input="updateField('cc', cc)"
              type="text"
              placeholder="CC emails"
              class="no-shadow-box w-full border-t-0 border-l-0 border-r-0 border-b-2 border-[#E6E7E8]"
            />
            <input
              v-if="visibleBcc"
              id="bcc"
              v-model="bcc"
              @input="updateField('bcc', bcc)"
              type="text"
              placeholder="BCC emails"
              class="no-shadow-box w-full border-t-0 border-l-0 border-r-0 border-b-2 border-[#E6E7E8]"
            />
            <input
              id="subject"
              v-model="subject"
              @input="updateField('subject', subject)"
              type="text"
              placeholder="Email subject"
              class="no-shadow-box w-full border-t-0 border-l-0 border-r-0 border-b-2 border-[#E6E7E8]"
            />
            <div class="px-4 mb-4 w-full flex flex-start gap-4">
              <div class="file-input-wrapper">
                <input
                  id="attachments"
                  type="file"
                  class="file-input hidden cursor-pointer"
                  multiple
                  @change="onFileChange"
                />
                <label
                  for="attachments"
                  class="file-input-label flex gap-2 text-[#2C3449] text-[16px] cursor-pointer"
                >
                  <Icon name="attachments" />
                  pièces jointes
                </label>
              </div>
            </div>
            <RichText
              :content="body"
              @get-content="handleGetContent"
              class="p-0 w-full mail-send"
            />
            <div class="flex flex-row justify-end gap-4 w-full">
              <Button
                type="submit"
                class="bg-[#3D5AA6] px-10 py-5 text-lg text-white rounded-sm"
              >
                <Icon
                  v-if="isloading"
                  name="spinner"
                  class="h-8 w-8 animate-spin"
                />
                <div v-else class="flex flex-row justify-center items-center">
                  <Icon name="sendMail" class="mr-4" />
                  Envoyer
                </div>
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      v-if="emailState.minimized"
      class="flex flex-row gap-6 justify-between items-center rounded-t-2xl border-t-4 shadow-md shadow-[#4D80C9] border-l-4 border-r-4 border-[#4D80C9] fixed bottom-0 right-16 pl-6 pr-4 py-3 bg-white cursor-pointer"
    >
      <span class="font-bold text-lg" @click="maximizePopup">
        Nouveau message
      </span>
      <div class="flex justify-center items-center gap-3">
        <Icon
          name="expanded"
          class="text-[#4D80C9] h-4"
          @click="maximizePopup"
        />
        <Icon
          name="close"
          class="text-[#4D80C9] h-6"
          @click="emailPopupClose"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  addFileAttachment,
  emailPopupClose,
  emailState,
  loadEmailState,
  maximizePopup,
  minimizePopup,
  updateEmailField,
} from "../composables/useEmailStore";

onMounted(() => {
  loadEmailState();
});

const to = ref(emailState.to.join(", "));
const cc = ref(emailState.cc.join(", "));
const bcc = ref(emailState.bcc.join(", "));
const subject = ref(emailState.subject);
const body = ref(emailState.body);

watch(
  emailState,
  () => {
    to.value = emailState.to.join(", ");
    cc.value = emailState.cc.join(", ");
    bcc.value = emailState.bcc.join(", ");
    subject.value = emailState.subject;
    body.value = emailState.body;
  },
  { deep: true }
);

const visibleCC = ref(false);
const visibleBcc = ref(false);
const isloading = ref(false);

const handleCC = () => {
  visibleCC.value = !visibleCC.value;
};

const handleBcc = () => {
  visibleBcc.value = !visibleBcc.value;
};

const handleGetContent = async (content: string) => {
  body.value = content;
};
const updateField = (
  field: keyof typeof emailState,
  value: string | string[]
) => {
  if (Array.isArray(value)) {
    updateEmailField(field, value);
  } else {
    updateEmailField(
      field,
      value.split(", ").map((email) => email.trim())
    );
  }
};

const onFileChange = async (event: Event) => {
  const input = event.target as HTMLInputElement;
  if (input.files) {
    Array.from(input.files).forEach(async (file) => {
      const fileUrl = await uploadFile(file);
      addFileAttachment(fileUrl, file.name);
    });
  }
};

const uploadFile = (file: File): Promise<string> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(URL.createObjectURL(file)); // Replace this with actual upload URL
    }, 1000);
  });
};

// const removeFileAttachment = (index: number) => {
//   // Implementation for removing file attachment
// };

const sendEmail = () => {};
</script>

<style scoped>
.wrapper-email-popup {
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(248, 248, 248, 0.8);
  position: absolute;
  /* z-index: 99999999; */
}

.email-popup {
  position: fixed;
  min-width: 900px;
  min-height: 500px;
  background-color: #fff;
  transition:
    height 0.3s,
    width 0.3s;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.25);
}

.email-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #f1f1f1;
}

.email-body {
  padding: 10px;
}
</style>
